svg {
    display: inline-block;
    vertical-align: middle;
  }
  
  path {
    fill: #fff;
  }

 

  .custom-tooltip {
    background-color: white  !important;
    color:  black!important;
}